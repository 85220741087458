<template>
  <!-- <div :class="[flag? '': 'content-box']"> -->
  <!-- <div :class="[flag? '': 'container']"> -->
  <div class="content set">
    <div>
    <h4 class="global_head_title head_title">工人设置</h4>
    <div class="content_row flex">
      <label class="info" for=""> 保险到期是否开启</label>
      <div class="content_right">
        <a-switch
          checked-children="开"
          un-checked-children="关"
          v-model="onSwitch"
        />
      </div>
    </div>
    <div class="content_row flex">
      <label class="info" for=""> 保险到期提醒天数</label>
      <div class="content_right">
        <a-select size="large" v-model="data.insuranceterm_day" placeholder="">
						<a-select-option v-for="(item,index) in dateArr" :key="index" :value="item.value">
							{{ item.label }}
						</a-select-option>
					</a-select>
        <!-- <a-input
          v-model="data.insuranceterm_day"
          size="large"
          placeholder="*天"
        /> -->
      </div>
    </div>
    <div class="content_row flex">
      <label class="info" for=""> 保险到期提醒时间</label>
      <div class="content_right">
        <a-time-picker v-model="i_time" size="large" format="HH:mm" />
      </div>
    </div>
    <div class="content_row flex">
      <label class="info" for="">回访提醒频率</label>
      <div class="content_right">
        <a-select size="large" v-model="data.visit_day" placeholder="">
						<a-select-option v-for="(item,index) in frequencyArr" :key="index" :value="item.value">
							{{ item.label }}
						</a-select-option>
					</a-select>
        <!-- <a-input v-model="data.visit_day" size="large" placeholder="*天" /> -->
      </div>
    </div>
    <div class="content_row flex">
      <label class="info" for="">回访提醒时间</label>
      <div class="content_right">
        <a-time-picker v-model="v_time" size="large" format="HH:mm" />
      </div>
    </div>
      <div class="save">
        <a-button type="primary" @click="submit">保存</a-button>
      </div>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
<script>
// import $ from "jquery";
import { isMobile, ajaxUrl, requestXml } from "../../../assets/js/request";
import moment from "moment";
export default {
  props: {
    addFlag: {
      type: Boolean,
      default: false,
    },
    taskId: {
      type: String,
    },
  },
  data() {
    return {
      flag: true,
      id: "",
      ajaxUrl: ajaxUrl,
      staff: "", //员工列表
      skillid: "", //技能列表
      dateArr: [
				{"value": "0", "label": "不提醒"},
				{"value": "1", "label": "提前一天"},
				{"value": "3", "label": "提前三天"},
				{"value": "7", "label": "提前一周"},
				{"value": "14", "label": "提前两周"},
				{"value": "30", "label": "提前一个月"},
			],
			frequencyArr: [
				{"value": "0", "label": "不提醒"},
				{"value": "1", "label": "每天一次"},
				{"value": "7", "label": "一周一次"},
				{"value": "14", "label": "两周一次"},
				{"value": "30", "label": "一月一次"},
				{"value": "90", "label": "三月一次"},
				{"value": "180", "label": "半年一次"},
				{"value": "365", "label": "一年一次"},
			],
      data: {
        insuranceterm_day: "1",
        insuranceterm_time: "",
        visit_day: "1",
        visit_time: "",
        insuranceterm_status: "1",
      },
      i_time: moment("08:00", "HH:mm"),
      v_time: moment("08:00", "HH:mm"),
      onSwitch: "",
    };
  },
  mounted: function () {
    this.id = this.$route.query.id;
    this.getDetail();
    if (isMobile()) {
      this.flag = true;
    } else {
      this.flag = false;
    }
  },

  methods: {
    moment,
    submit() {
      let url = "/jieb/Project/projectsetting";
      console.log(this.data);
      this.data.insuranceterm_time = moment(this.i_time).format("HH:mm");
      this.data.visit_time = moment(this.v_time).format("HH:mm");
      if (this.onSwitch) {
        this.data.insuranceterm_status = 1;
      } else {
        this.data.insuranceterm_status = 2;
      }
      requestXml(
        url,
        "POST",
        (status, res) => {
          console.log(status, res);
          this.getDetail();
        },
        this.data
      );
    },
    // 获取详情
    getDetail() {
      requestXml(
        "/jieb/Project/projectsettshow",
        "GET",
        (res) => {
          if(res=='success'){
            return
          }
          // (1=>开启,2=>关闭) 是否开启
          if (res.insuranceterm_status == 1) {
            this.onSwitch = true;
          }
          this.i_time = moment(res.insuranceterm_time, "HH:mm");
          this.v_time = moment(res.visit_time, "HH:mm");
          if (res.insuranceterm_status == 1) {
            this.onSwitch = true;
          } else {
            this.onSwitch = false;
          }
          this.data = Object.assign({}, res);
        },
        { id: this.id }
      );
    },
  },
};
</script>
<style scoped>
.head_title{
  padding-bottom: 10px;
}
.action {
  display: inline-block;
  padding: 5px 10px;
  margin: 0 5px;
  background: #dceeff;
  border: 1px solid #36a3ff;
  color: #36a3ff;
  border-radius: 5px;
}
.del {
  background: #ffdcdc;
  border-color: #ff4c4c;
  color: #ff4c4c;
}
.content .content_row {
  align-items: center;
  margin: 2rem 0;
}
.content_right {
  width: calc(100% - 120px);
  text-align: left;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #36a3ff;
  border-color: #36a3ff;
}
.content .content_row .info {
  display: inline-block;
  width: 15rem;
  text-align: right;
  padding-right: 1rem;
  color: #202020;
  font-weight: normal;
}

.content .content_row .fileLabel {
  line-height: 1.499;
  font-weight: 400;
  background-image: none;
  border: 1px solid #d9d9d9;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  height: 32px;
  line-height: 32px;
  padding: 5px 15px;
  font-size: 14px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
}
.content .content_row input[type="file"] {
  display: none;
}
/* 输入框 下拉框 */
.content .content_row input,
.content .content_row .ant-select {
  width: 100%;
  /* background-color: #f9f9f9; */
}
.content .content_row .ant-select-selection--multiple input {
  width: 0;
}
/* 日期 */
.content .content_row .ant-calendar-picker {
  width: 100% !important;
}
.content .content_row .ant-calendar-picker input {
  width: 100%;
}

.save {
  text-align: left;
}
.save .ant-btn {
  width: 100px;
  height: 40px;
  background: #36a3ff;
  border-radius: 4px;
  margin-left: 13rem;
}

@media screen and (min-width: 750px) {
  .content .content_row {
    margin: 15px 0;
    font-size: 16px;
  }
  .content .content_row .info {
    width: 90px;
    padding-right: 10px;
  }
  .set .content_row .info {
    width: 106px;
  }
  .content .content_row .ant-calendar-picker {
    width: 400px !important;
  }
  .content .content_row .ant-calendar-picker input {
    width: 100% !important;
  }
  /* input ,selsct */
  .content .content_row input,
  .content .content_row .ant-select,
  .content .content_row textarea {
    width: 400px;
  }
  .save .ant-btn {
    margin-left: 90px;
  }
}
</style>
